.list-with-buttons.list-with-hierarchy{

  .icons{
    align-self: start;
    padding-top: $size8;
  }
}

.root-name {
  display: inline-block;
  cursor: pointer;

  + .hierarchy-list > ul {
    display: none;
  }

  &.has-children{

    &:before {
      font-family: "Font Awesome 5 Pro";
      content: "\f078";
      font-size: $size18;
      margin-right: $size5;
      font-weight: normal;
    }

    &.active {
      &:before {
        content: "\f077";
        color: $niceBlue;
      }

      + .hierarchy-list > ul {
        padding-left: $size8;
        display: block
      }
    }

  }
}

.hierarchy-list {
  display: block;
  font-size: $newFontSize20;

  ul li {
    display: block;
    padding: 0;
    line-height: 1.2;
    color: $warmGrey;
    border: unset;
    cursor: pointer;

    @include hasHover {
      > span:hover{
        color: $niceBlue;
      }
    }

    &:before {
      font-family: "Font Awesome 5 Pro";
      content: "\f078";
      margin-right: $size10;
      font-weight: normal;
      color: transparent;
    }
    &.has-children:before {
      color: $pinkishGrey;
    }

    > ul {
      padding-left: $size28;
      display: none;
    }

    &.active {

      &:before {
        content: "\f077";
        color: $niceBlue;
      }

      > ul {
        display: block;
      }
    }

  }
}