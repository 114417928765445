//recaptcha css mobile width fix
@media screen and (max-width: $size380) {

    #rc-imageselect,
    .g-recaptcha {
        transform: scale(0.9);
        -webkit-transform: scale(0.9);
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
    }
}

@media screen and (max-width: $size360) {

    #rc-imageselect,
    .g-recaptcha {
        transform: scale(0.85);
        -webkit-transform: scale(0.85);
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
    }
}

@media screen and (max-width: $size330) {

    #rc-imageselect,
    .g-recaptcha {
        transform: scale(0.74);
        -webkit-transform: scale(0.74);
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
    }
}


//stuff for recaptcha modal
.doods-modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);

    & .doods-modal-overlay {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    &>.doods-modal-loading {
        /*background-color: #fefefe;*/
        margin: 20% auto;
        color: white;
        /*border: $size1 solid #888;*/
        /*width: 80%;*/
        /*max-width: $size480;*/
        /*border-radius: $size8;*/
        z-index: 2;
    }


    &>.doods-modal-content {
        background-color: #fefefe;
        margin: 20% auto;
        border: $size1 solid #888;
        width: 80%;
        max-width: $size480;
        border-radius: $size8;
        box-shadow: 0 $size20 $size30 rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 0 $size20 $size30 rgba(0, 0, 0, 0.2);
        z-index: 2;

        &>.doods-modal-header {
            height: $size40;
            margin: 0 $size15;
            border-bottom: $size1 solid #00859A;
            position: relative;

            &>.doods-modal-close-btn {
                position: absolute;
                top: 0;
                right: $size5;
                font-size: $newFontSize25;
                padding: $size5;
                font-weight: bold;
                color: #aaa;
            }
        }

        &>.doods-modal-body {
            margin: $size15;
            position: relative;

            &>h1 {
                font-size: $newFontSize24;
                text-transform: uppercase;
                font-weight: 500;
            }

            .fa {
                font-size: $newFontSize65;
                color: #00859A;
            }

            &.center-content {
                text-align: center;
            }


        }

        &>.doods-modal-footer {
            margin: $size15;
            position: relative;

            .btn {
                min-width: $size100;
            }
        }


    }


}