.edit-team-page {
  .card-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: $size30;

    .content-inputs{
      grid-column: 1/3;
      display: grid;
      width: 100%;
      grid-template-columns: 1fr 1fr 1fr;
      @include upToPhoneLandscape {
        grid-template-columns: 1fr;
      }
      grid-gap: $size30;
      align-items: start;
    }
  }

  .role-list {
    grid-column: span 2;

    li h2{
      text-transform: uppercase;
    }
  }
}