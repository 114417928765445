.license-lock-wrapper {
  position: relative;
  display: grid;

  .license-lock-icon {
    display: none;
  }

  &.license-lock-active {
    .license-lock-icon {
      display: grid;
      align-items: center;
      justify-items: center;
      align-content: center;
      z-index: 2;

      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;

      i {
        color: $white;
        font-weight: bold;
        font-size: $newFontSize20;
        margin-bottom: $size10;
        cursor: pointer;

        &:only-child {
          margin: 0;
        }
      }

      h2 {
        color: $white;
        font-weight: bold;
        font-size: $newFontSize26;
        text-transform: uppercase;
        cursor: pointer;
      }

      p {
        color: $white;
        font-size: $newFontSize12;
        text-transform: uppercase;
        cursor: pointer;
      }
    }

    .license-lock-opacity {
      opacity: 0.2;
    }
  }
}