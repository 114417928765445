ul.list-with-buttons {
  @include upToPhoneLarge {
    font-size: $newFontSize13;
  }

  li{
    display: grid;
    grid-template-columns: 1fr min-content;
    align-items: center;
    //grid-row-gap: $size8;
    padding: $size20 0;
    border-bottom: $size1 solid $lightGray;

    .text {
      h3 { // user name
        font-size: $newFontSize24;
        font-weight: bold;
        color: $niceBlue;
        line-height: 1.5;

        i { // icons
          font-size: emCalc(24, 20);
          margin-left: emCalc(20, 10);
          color: $pinkishGrey;

          &.selected {
            color: $trueOrange;
          }
        }
      }

      h4 { // profiling result
        font-size: $newFontSize18;
        color: $warmGrey;
      }
    }

    .icons {
      @include upToPhoneSmall {
        display: none;
      }

      display: grid;
      grid-auto-columns: min-content;
      grid-auto-flow: column;
      justify-content: end;
      grid-gap: $size20;

      i { // icons
        font-size: $newFontSize20;
        color: $pinkishGrey;

        &.selected {
          color: $trueOrange;
        }
      }
    }
  }

  li:first-child {
    padding-top: 0;
  }

  li:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
}