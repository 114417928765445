.login-content {
  display: grid;
  height: 100vh;
  width: 100%;
  max-width: $size540;
  margin: 0 auto;
  @include upToPhoneLarge {
    font-size: $newFontSize14;
    padding: 0 0 $size60 0 !important;
  }

  .btn {
    width: 100%;
  }

  .logo {
    align-self: end;
    width: 100%;
    padding: 0 25%;
    margin-top: $size40;
    margin-bottom: $size40;
  }

  .content {
    .card {
      .card-body {
        .card-header {
          display: grid;
          grid-template-columns: 1fr 1fr;
          padding: $size50;
          background-color: $white;

          @include upToPhoneLarge {
            padding: $size30;
          }

          h1 {
            font-size: $newFontSize30;
            color: $darkBlue;
            text-transform: uppercase;
            font-weight: bold;
            align-self: center;
          }

          .form-input-wrap {
            .form-input-dropdown {
              .form-input {
                height: $size40;
                border: 1px solid $borderGray;
                background-color: $white;
                color: $darkBlue;
                text-transform: uppercase;
                font-weight: bold;
                font-size: $newFontSize16;
              }

              i {
                color: $niceBlue;
              }
            }
          }
        }

        .card-content {
          padding: 0 $size80;

          @include upToPhoneLarge {
            padding: 0 $size50;
          }

          .indent-wrap{
            margin: 0 -$size30;

            @include upToPhoneLarge {
              margin: 0 -$size20;
            }
          }

          h3{
            color:$niceBlue;
            font-weight: bold;
            font-size: $newFontSize20;
            margin-bottom: $size8;
          }

          p {
            margin-bottom: $size40;
            color: $warmGrey;
            font-size: $newFontSize16;
            line-height: 1.5;
          }

          &.login-success{
            padding: $size60;
            text-align: center;

            .success-icon{
              margin-bottom: $size58;

              i{
                font-size: $newFontSize70;
                color: $darkBlue;
              }
            }

            p{
              font-size: $newFontSize20;
              line-height: 1.2;
              margin-bottom: $size8;
            }
          }
        }

        .card-footer {
          padding: $size40 $size50;
          text-align: center;

          @include upToPhoneLarge {
            padding: $size40 $size30;
          }

          p {
            font-size: $newFontSize14;
            color: $warmGrey;
            font-weight: bold;
            line-height: 1.5;

            a{
              color: $niceBlue;
              display: inline;
            }
          }

          .small-text{
            text-align: left;
            p{
              margin-top: $size40;
              font-weight:normal;
              font-size: $newFontSize11;
            }
          }
        }
      }
    }
  }
}