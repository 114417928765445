
.report-graph-card {
  margin-bottom: 0;

  .card-body {
    background: $darkBlue;
  }
}

$graphMargin: $size50;
$smallGraphMargin: $size25;

.report-graph {
  display: grid;
  grid-template-columns: min-content 1fr max-content;
  grid-template-rows: min-content 1fr;
  grid-template-areas: //
          "title title filter" //
          "yAxis data data" //
          "none xAxis xAxis";
  margin: $size52 $size24 $size52 $size24;
  background: $darkBlue;

  @include upToPhoneLandscape {
    font-size: $size12;
    grid-template-columns: min-content 1fr;
    grid-template-rows: min-content min-content 1fr;
    grid-template-areas: //
            "title title" //
            "filter filter" //
            "yAxis data" //
            "none xAxis";
  }

  .title {
    font-size: $newFontSize30;
    font-weight: bold;
    color: $white;
    grid-column: span 2;
    margin-left: $size12;
    justify-self: start;
    text-transform: capitalize;
    margin-bottom: emCalc(30, 52);

    @include upToPhoneLandscape {
      margin-bottom:$size8;
    }

    & > h2 {
      display: inline-block;
      vertical-align: middle;
    }
    & > .btn {
      vertical-align: middle;
      display: inline-block;
      font-size: emCalc(30, 21);
      margin-left: $size16;
    }
  }

  .filter {
    @include upToPhoneLandscape {
      grid-column: span 2;
      margin-bottom: emCalc(30, 52);
    }
  }


  .graph {
    grid-area: data;
    display: grid;
    height: $size280;
    z-index: 1;
    margin: 0 $graphMargin;

    @include upToPhoneLandscape {
      height: emCalc(12, 150);
      margin: 0 $smallGraphMargin;
    }

    svg {
      //overflow: visible;

      path {
        stroke-width: 4;
        fill: none;
        stroke: url(#graph-gradient);
        vector-effect: non-scaling-stroke;
      }

      ellipse {
        stroke-width: 4;
        fill: url(#graph-gradient);
        vector-effect: non-scaling-stroke;
      }

      & > svg {
        path {
          fill: none;
          stroke: $lightGrayTrans;
          stroke-width: 3;
          stroke-dasharray: 8;
          stroke-linejoin: round;
        }
      }
    }
  }

  .scores {
    grid-area: data;
    position: relative;
    margin: 0 $graphMargin;
    z-index: 1;

    @include upToPhoneLandscape {
      margin: 0 $smallGraphMargin;
    }

    & > .score-dot {
      opacity: 0;
      position: absolute;
      display: grid;
      width: $size16;
      height: $size16;
      background-color: $everGreen;
      border-radius: 50%;
      border: $size3 solid $white;
      color: $everGreen;
      transform: translateY(50%) translateX(-50%);
      align-items: center;
      justify-items: center;

      & > .score-dot-hover {
        pointer-events: none;
        top: 0;
        left: 0;
        position: absolute;
        display: grid;
        margin-top: -$size80;
        padding: $size10;
        width: auto;
        text-align: center;
        background-color: $white;
        color: $everGreen;
        align-items: center;
        justify-items: center;

        span {
          font-size: $newFontSize20;
          font-weight: bold;
          cursor: pointer;

          &.point-date {
            font-size: $newFontSize12;

            & > .no-wrap {
              white-space: nowrap;
              font-size: inherit;
            }
          }
        }
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  .y-axis {
    grid-area: yAxis;
    position: relative;
    width: $size30;

    .tick {
      position: absolute;
      transform: translateY(50%);
      font-size: $newFontSize18;
      font-weight: bold;
      color: $trueBlue;
    }
  }

  .x-axis {
    grid-area: xAxis;
    position: relative;
    height: $size30;
    margin: 0 $graphMargin;
    white-space: nowrap;

    @include upToPhoneLandscape {
      margin: 0 $smallGraphMargin;
    }

    .tick {
      position: absolute;
      transform: translateY(100%) translateX(-75%);
      font-size: $newFontSize14;
      font-weight: bold;
      color: $trueBlue;
      text-transform: uppercase;
      margin-top: $size16;

      .tick-rotated {
        transform: rotate(-45deg);
      }
    }
  }

  .vertical-grid {
    grid-area: data;
    position: relative;

    .vertical-grid-line {
      position: absolute;
      width: 100%;
      height: $size1;
      min-height: 1px;
      border-bottom: $size1 solid $trueBlueTrans2;
    }
  }

  .horizontal-grid {
    grid-area: data;
    position: relative;
    margin: 0 $graphMargin;

    @include upToPhoneLandscape {
      margin: 0 $smallGraphMargin;
    }

    .horizontal-grid-line {
      position: absolute;
      height: 100%;
      width: $size1;
      min-width: 1px;
      border-left: $size1 solid $trueBlueTrans2;
    }
  }
}