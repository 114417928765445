.fb-share-button {
  border-radius: 4px;
  height: 22px;
  padding: 0 0 1px 2px;
  background: #4267b2;
  border: 1px solid #4267b2;
  color: #FFF;
  cursor: pointer;
  margin: 0;
  white-space: nowrap;
  display: inline-block;
  position: absolute;
  top: 20px;
  right: 20px;

  &:hover {
    background: #365899;
    border: 1px solid #365899;
  }
}

.fb-logo {
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
}

.fb-share-text {
  vertical-align: middle;
  padding-left: 4px;
  padding-right: 4px;
  font-weight: bold;
  font-size: 12px;
}