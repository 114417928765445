.card.company-register-info {
  .card-body {
    & > .card-content {
      h3 {
        font-size: $newFontSize20;
        color: $niceBlue;
        line-height: $size24;
      }

      p {
        border: none;
        padding: 0;
        font-size: $newFontSize16;
        line-height: $size24;
        color: $warmGrey;
      }
    }
    & > .card-footer {
      display: grid;
      padding: $size50;
      .btn {
        width: auto;
        &.btn-register-company {
          margin: 0 auto;
        }
      }
    }
  }
}

.non-company-welcome-page {
  display: grid;
  grid-template-columns: 1fr min-content 1fr;

  @include upToTablet {
    grid-template-columns: auto;
  }

  .card:nth-of-type(1) {
    .card-header-icon {
      background-color: $trueOrange;
    }

    .btn {
      background-color: $trueOrange;
    }
  }

  .steps-column {
    display: grid;
    grid-template-rows: 1fr min-content 1fr;
    justify-items: center;
    margin: 0 $size24;

    @include upToTablet {
      align-items: center;
      grid-template-rows: auto;
      grid-template-columns: 1fr min-content 1fr;
      margin: 0 0 $size20 0;
    }

    .v-line {
      width: $size0_5;
      @include upToTablet {
        height: $size0_5;
        width: 100%;
      }
      border: $size0_5 solid $niceBlue;
    }

    .steps-circle {
      align-self: center;

      span {
        color: $white;
      }
    }
  }
}