.edit-user-page{
  .card-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: $size30;
    grid-row-gap: $size40;
    @include upToPhoneLandscape {
      .form-input-wrap {
        grid-column: span 3;
      }
    }
  }

  .role-list {
    grid-column: span 3;

    li h2{
      text-transform: uppercase;
    }
  }
}