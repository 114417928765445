/* Step List - A/B */
.profilingContent {
  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8%;

    @include upToPhoneLarge {
      grid-template-columns: unset;
      grid-template-rows: 1fr 1fr;
      grid-gap: unset;
    }

    label {
      cursor: pointer;
    }

    input[type=radio]:checked + .card {
      & > .card-body > .card-header > .card-header-icon {
        background-color: $trueOrange;
      }
    }

    .card {
      .card-body {
        .card-header {
          .card-header-icon {
            background-color: $niceBlue;

            i {
              size: $size50;
              font-weight: bold;
            }
          }
        }

        .card-content {
          padding: $size20 $size16;

          & > div {
            display: grid;
            grid-template-rows: repeat(5, minmax(#{$size55}, 1fr));
            @include upToPhoneLarge {
              grid-template-rows: repeat(5, auto);
            }

            & > div {
              height: 100%;
              display: grid;
              align-items: center;
              border-bottom: $size1 solid $lightGray;

              &:last-child {
                border-bottom: 0;
              }

              span {
                @include upToPhoneLarge {
                  padding: $size10 0;
                }
                color: $warmGrey;
                font-size: $newFontSize13;
                line-height: 1.5;
              }
            }

            @include upToPhoneLarge {
              & > div:first-child {
                span {
                  padding-top: 0;
                }
              }
              & > div:last-child {
                span {
                  padding-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}



