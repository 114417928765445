/*--------------------------------------------------------------------------*/
/* Animations */
/* Child Animation - SlideIn */
@for $i from 1 through 10 {
    .slideIn {
        &:nth-child(#{$i}) {
            animation-delay: $i * (.15s);
        }
    }

    .slideDown {
        &:nth-child(#{$i}) {
            animation-delay: $i * (.15s);
        }
    }
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translate3d(0, $size113, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideOut {
    0% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    100% {
        opacity: 0;
        transform: translate3d(0, $size113, 0);
    }
}

@keyframes slideDown {
    0% {
        opacity: 0;
        transform: translate3d(0, -$size113, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

/* Info Box In/Out */
@keyframes infoIn {
    0% {
        opacity: 0;
        transform: translate3d(-50%, $size113, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(-50%, 0, 0);
    }
}

@keyframes infoOut {
    0% {
        opacity: 1;
        transform: translate3d(-50%, 0, 0);
    }

    100% {
        opacity: 0;
        transform: translate3d(-50%, $size113, 0);
    }
}

/* Notification Animation - PopUp */
@keyframes notifyIn {
    0% {
        opacity: 0;
        transform: translate3d(-50%, -200%, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(-50%, 0, 0);
    }
}

@keyframes notifyOut {
    0% {
        opacity: 1;
        transform: translate3d(-50%, 0, 0);
    }

    100% {
        pointer-events: none;
        opacity: 0;
        transform: translate3d(-50%, -200%, 0);
    }
}

/* Floating Notification Animation - PopUp */
@keyframes popUp {
    0% {
        opacity: 0;
        transform: translate3d(0, $size113, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

/* List Out Animation - Lists */
@keyframes listOut1 {
    0% {
        opacity: 0;
        height: 0;
        transform: translate(-50%, 0);
    }

    100% {
        opacity: 1;
        height: $size16;
        transform: translate(-50%, 0);
    }
}

@keyframes listOut2 {
    0% {
        opacity: 0;
        height: 0;
        transform: translate3d(-50%, 0, 0);
    }

    100% {
        opacity: 1;
        height: $size32;
        transform: translate3d(-50%, 0, 0);
    }
}

@keyframes slideInRight {
    0% {
        opacity: 0;
        transform: translate3d($size320, 0 ,0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideOutRight {
    0% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    100% {
        opacity: 0;
        transform: translate3d($size320, 0 ,0);
    }
}

.slideInRight {
    animation: .75s ease-in-out both slideInRight;
}

.slideOutRight {
    animation: .75s ease-in-out both slideOutRight;
}

.slideIn {
    animation: .75s ease both slideIn;
}

.slideOut {
    animation: .75s ease both slideOut;
}

.slideDown {
    animation: .75s ease both slideDown;
}

.popUp {
    animation: .75s ease both popUp;
}

.infoIn {
    animation: .75s ease both infoIn;
}

.infoOut {
    animation: .75s ease both infoOut;
}

.notifyIn {
    animation: .75s ease both notifyIn;
}

.notifyOut {
    animation: .75s ease both notifyOut;
}

/* Background Animation */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.fadeIn {
    animation: .75s ease-in-out both fadeIn;
}

.fadeOut {
    animation: .75s ease-in-out both fadeOut;
}