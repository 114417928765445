.view-user-page, .view-team-page {
  .card {
    .card-body {
      .card-header-title {
        .btn.plain {
          &:first-of-type {
            //margin-right: $size24;
            @include upToPhoneLarge {
              //margin-right: $size8;
            }
          }
        }
      }
    }
  }
}

.user-in-team-item {
  .icons {
    i { // icons
      margin-left: emCalc(20, 10);
      color: $pinkishGrey;

      &:last-of-type {
        margin-right: emCalc(20, 10);
      }

      &.selected {
        color: $trueOrange;
      }
    }
  }

  @include upToPhoneLarge {
    .icons {
      display: none;
    }
  }
}