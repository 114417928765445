@keyframes waveLoop {
  0% {
    background-position: 0 100%, 0 0;
  }

  50% {
    background-position: -450% 200%, 450% 100%;
  }

  100% {
    background-position: -900% 100%, 900% 0;
  }
}

@keyframes bubbles {
  0% {
    bottom: -20%;
  }

  50% {
    background-color: rgba(255, 255, 255, 0.25);
  }

  100% {
    bottom: 70%;
    background-color: rgba(255, 255, 255, 0);
  }
}

.new-wave {
  position: relative;
  width: $size270;
  height: $size270;
  border-radius: 50%;
  border: $size11 solid $niceBlue;
  background: $darkBlue;
  overflow: hidden;

  display: grid;
  justify-content: center;
  align-content: center;
  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);

  .wave {
    position: absolute;
    top: $size100;
    width: inherit;
    height: inherit;
    //background-size: 100% 100%, 100% 100%;
    background-repeat: repeat-x;
    background-size: 90% 90%, 90% 90%;
    background-position: 900% 0;
    animation-name: waveLoop;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    &.red {
      background-image: url('../../../img/slider-wave-front-red.svg'), url('../../../img/slider-wave-back-red.svg');
    }

    &.orange {
      background-image: url('../../../img/slider-wave-front.svg'), url('../../../img/slider-wave-back.svg');
    }

    &.green {
      background-image: url('../../../img/slider-wave-front-green.svg'), url('../../../img/slider-wave-back-green.svg');
    }
  }

  .bubbles {
    div {
      position: absolute;
      bottom: 50%;
      width: $size25;
      height: $size25;
      background-color: rgba(255, 255, 255, 0);
      border-radius: 100%;
      animation-name: bubbles;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in;

      &.first {
        left: 55%;
        width: $size12;
        height: $size12;
        animation-duration: 2s;
      }

      &.second {
        left: 65%;
        animation-duration: 3s;
      }

      &.third {
        left: 20%;
        animation-duration: 1.5s;
      }
    }
  }

  span {
    color: $white;
    font-size: $newFontSize98;
    font-weight: bold;
    z-index: 10;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}