.soluting-list {
  .soluting-group {
    .group-title {
      text-align: center;
      margin: 0 0 $size50 0;
      h2 {
        color: $white;
        font-weight: bold;
        font-size: $newFontSize24;
      }
    }

    .group-slider {
      position: relative;

      .btn {
        i {
          font-size: $newFontSize24;
        }

        &.l, &.r {
          position: absolute;
          z-index: 1;
          top: calc(#{$size200} - #{$size30}); //image height - half button height
        }

        &.l {
          left: calc(-2*#{$size30} - #{$size50});

          @include upToDesktop {
            left: 0;
          }
        }

        &.r {
          right: calc(-2*#{$size30} - #{$size50});

          @include upToDesktop {
            right: 0;
          }
        }
      }

      .group-items-wrapper{
        position: relative;
        overflow-x: scroll;
        scrollbar-width: none;
        margin-bottom: -17px; // leave pixels in because that's browser scroll size!!!
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
        &::-webkit-scrollbar {
          margin-bottom: 17px; // leave pixels in because that's browser scroll size!!!
        }

        .group-items {
          display: grid;
          grid-auto-columns: $size280;
          grid-auto-flow: column;
          grid-column-gap: $size30;
          margin-bottom: $size16;

          .card.group-item {
            width: $size280;

            .card-body {
              position: relative;
              height: 100%;
              cursor: pointer;

              .soluting-card-image {
                position: relative;
                box-shadow: none;
                -webkit-box-shadow: none;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                background-color: $darkBlue;
                height: $size200;

                .group-name-header {
                  padding: $size12 $size20;
                  color: $white;
                  font-weight: bold;
                  background-color: $niceBlue;
                  border-radius: 0 0 $elBorderRadius 0;
                  position: absolute;
                  top: 0;
                  left: 0;
                }
              }

              .card-header {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr min-content;
                min-height: $size58;
                background-color: $white;
                padding: $size13;
                align-items: center;

                .header-title {
                  font-size: $newFontSize18;
                  font-weight: bold;
                  line-height: 1.5;

                  span:not(:first-child) {
                    font-size: emCalc(18, 13);
                    display: block;
                    color: $darkBlue;
                    line-height: 1;

                    &.cut-out{
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-line-clamp: 2;
                      /*! autoprefixer: ignore next */
                      -webkit-box-orient: vertical;
                      line-height: 1.2em;
                      max-height: calc(1.2em * 2);
                    }
                  }
                }
                .header-favorite-action {
                  i {
                    font-size: $newFontSize24;
                    color: $darkBlue;
                  }
                }
              }
              .card-content {
                padding: 0 $size13 $size13 $size13;

                h3 {
                  font-size: $newFontSize18;
                  line-height: 1.4;
                  color: $darkBlue;
                  font-weight: bold;
                  margin-bottom: emCalc(18, 13);
                }
                p {
                  font-size: $newFontSize13;
                  color: $warmGrey;
                  line-height: 1.5;
                  margin-bottom: $size25;
                  min-height: emCalc(13, 60);

                  &:last-child{
                    margin-bottom: 0;
                  }

                  &.cut-out{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    /*! autoprefixer: ignore next */
                    -webkit-box-orient: vertical;
                    line-height: 1.2em;
                    max-height: calc(1.2em * 4);
                  }
                }
              }
            }

            &.facts {
              .card-body {
                .card-header .header-title {
                  color: $factsColor;
                }
                .soluting-card-image .group-name-header{
                  background-color: $factsColor;
                }
              }

            }
            &.relationship {
              .card-body {
                .card-header .header-title {
                  color: $relationshipColor;
                }
                .soluting-card-image .group-name-header{
                  background-color: $relationshipColor;
                }
              }

            }
            &.culture {
              .card-body {
                .card-header .header-title {
                  color: $cultureColor;
                }
                .soluting-card-image .group-name-header{
                  background-color: $cultureColor;
                }
              }

            }
            &.growth {
              .card-body {
                .card-header .header-title {
                  color: $growthColor;
                }
                .soluting-card-image .group-name-header{
                  background-color: $growthColor;
                }
              }
            }

            &.recommendation {
              .card-body {
                .card-header .header-title {
                  color: $recommendationColor;
                }
                .soluting-card-image .group-name-header{
                  background-color: $recommendationColor;
                }
              }
            }
          }
        }
      }
    }
  }
}
