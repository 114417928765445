.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: $darkBlue;
  color: $white;
  font-family: $roboto;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: $size80;
  border: none;
  outline: none;
  box-shadow: none;
  padding: $size20;
  transition: ease .25s;
  font-size: 1rem; // don't scale buttons
  @include upToPhoneLarge {
    font-size: 0.875rem;
    padding: emCalc(16, 16);
  }

  @include hasHover {
    &:hover:not(:disabled) {
      color: $trueOrange !important;

      i {
        color: $trueOrange !important;
      }
    }
  }

  span {
    font-size: $newFontSize18;
  }

  &:disabled {
    //opacity: 0.5;
    background-color: #005562;
    color: #7fc2cc;

    i {
      color: #006d7e;
    }
  }

  &:before,
  &:after,
  &:focus {
    content: '';
    content: none;
    border: none;
    outline: none;
    box-shadow: none;
  }

  i {
    width: auto;
    vertical-align: middle;
    backface-visibility: hidden;
    color: $trueBlue;
    font-size: $newFontSize18;
    transition: ease .25s;

    &:last-child {
      margin-left: emCalc(26, 20);
    }

    &:first-child {
      margin-right: emCalc(26, 20);
    }

    &:only-child {
      margin-left: 0;
      margin-right: 0;
    }
  }

  //&:hover i {
  //  transform: rotate(360deg) translate3d(0, 0, 0);
  //}

  &.btnSmall {
    padding: $size14 $size24;;

    span {
      font-size: $newFontSize12;
    }
  }

  &.plain {
    padding: 0;
    background: unset;
  }

  &.none {
    padding: 0;
    background: unset;
  }

  &.inherit{
    font-size: inherit;
    color: inherit;
    text-transform: inherit;
    font-weight: inherit;
    text-align: inherit;

    span {
      font-size: inherit;
    }
  }

  &.blue {
    background-color: $tealBlueTwo;

    @include hasHover {
      &:hover {
        background-color: $darkBlue;
      }
    }
  }
}