/* Survey SolutingSlider, Open Question, Feedback and well done Screens */

.survey-textbox {
  position: relative;
  width: 100%;

  .new-wave {
    width: $size400;
    height: $size400;
  }

  .survey-text {
    background-color: $niceBlue;
    text-align: center;
    overflow: hidden;
    border-radius: $size10;
    padding: $size20;
    @include upToPhoneLarge {
      font-size: $newFontSize10;
    }

    p.steps {
      font-size: $newFontSize24;
      margin-bottom: emCalc(24, 16);
      color: $darkGreenBlue;
      font-weight: bold;
      text-align: center;
    }

    p {
      color: $white;
      font-size: $newFontSize22;
      font-weight: bold;
      text-align: center;
    }
  }
}

.survey-anonymous-note{
  color: #ffffff;
  font-size: 1em;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}


.card.card-survey-question {
  .card-body {
    & > .card-content {

      h3 {
        font-size: $newFontSize24;
        color: $niceBlue;
        line-height: $size24;
        font-weight: bold;
        margin-bottom: $size8;
      }

      p {
        border: none;
        padding: 0;
        font-size: $newFontSize18;
        line-height: $size24;
        color: $warmGrey;
      }

      textarea {
        @include placeholder {
          color: $warmGray;
          opacity: 0.8;
        }
        resize: none;
        width: 100%;
        color: $darkBlue;
        font-family: Roboto, sans-serif;
        font-size: $newFontSize18;
        border: none;
        background-color: $lightGray;
        border-radius: $size10;
        padding: $size16;
      }
    }
    .card-footer {
      & > .skip-label {
        font-size: $newFontSize18;
        line-height: $newFontSize36;
      }
    }
  }

  &.initial-survey .card-footer {
    width: 100%;
    text-align: center;

    .btn {
      width: auto;
      max-width: $size216;

      &.btn-start-initial {
        margin: 0 auto;
        background-color: $niceBlue;
        max-width: $size260;
      }
    }
  }

  &.open-question .card-footer, &.feedback .card-footer, &.coq .card-footer {
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-gap: $size20;

    & > :nth-child(1) { // note for skip
      color: $darkGray;
    }

    @include upToPhoneLarge {
      grid-template-columns: 1fr;
      & > :nth-child(1) { // note for skip
        grid-row: 2 / 3;
        justify-self: center;
      }
      & > :nth-child(2) { // anonymous checkbox
        grid-row: 1 / 2;
        justify-self: end;
      }
    }
  }

  &.coq {
    .card-header-title {
      color: $trueOrange;
    }
    .card-header-icon {
      background: $trueOrange;
    }
    .card-content h3 {
      color: $trueOrange;
    }

    span.slider {
      background: $lightGray;

      &::before {
        background: $darkBlue;
      }
    }
  }
}


.survey-result {
  text-align: center;

  p {
    color: $white;
    margin-top: $size40;
    margin-bottom: $size54;
    text-align: center;
    line-height: 1.4;
    font-size: $newFontSize18;
  }
}