/*
whenever using app-footer-automatic put -parent on parent container
so that margin will be properly set when layout goes to fixed
 */
.app-footer-automatic-parent {
  //@include upToPhoneLarge {
  //  padding-bottom: $size66 !important;
  //}
}

.button-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: $size20 0;

  .horizontal-line {
    flex-grow: 1;
  }

  h3 {
    color: $white;
    font-size: $newFontSize30;
    font-weight: bold;

    @include upToPhoneLarge {
      font-size: $newFontSize16;
    }
  }

  & > p {
    color: $white;
    text-align: left;
    font-weight: bold;
  }
}

.app-footer-automatic {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: $size20 0;

  .btn {
    &.left {
      grid-area: left;
    }

    &.right {
      grid-area: right;
    }

    &.center {
      grid-area: center;
    }
  }

  .steps-circle {
    grid-area: center;
  }

  .horizontal-line {
    flex-grow: 1;
  }

  @include upToPhoneLarge {
    position: fixed !important;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0 !important;
    align-items: flex-end;


    .steps-circle {
      font-size: $newFontSize16;
      border-radius: emCalc(16, 20) emCalc(16, 20) 0 0;
      border-width: $size2;
      border-bottom: 0;
      height: auto;
      width: emCalc(16, 100);
      padding: emCalc(16, 16) 0;
    }

    .btn {
      position: relative;
      border: $niceBlue solid;

      &.left {
        border-radius: 0 $size30 0 0;
        padding: $size20;
        padding-right: $size25;
        border-width: $size2 $size2 0 0;
      }

      &.right {
        border-radius: $size30 0 0 0;
        padding: $size20;
        padding-left: $size25;
        border-width: $size2 0 0 $size2;
      }

      &.center {
      }
    }

    &::before {
      display: none;
    }

    .horizontal-line.line-left, .horizontal-line.line-right {
      display: none;
    }
  }
}

