.subscription-plan-content {
  .card-body {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    overflow: auto;

    & > div {
      display: grid;
      grid-template-rows: 1fr 158px repeat(10, 60px);
    }

    .description-header {
      height: 100%;
      background-color: $darkBlue;
      color: $white;
      padding: $size28 $size12;
      & > div {
        display: grid;
        grid-template-columns: min-content 1fr;
        align-items: center;
        i {
          width: $size20;
          text-align: center;
          font-size: $iconSize;
          margin-right: $size6;
          align-self: start;
        }
        span {
          font-size: $newFontSize14;
          font-weight: bold;
        }
      }
    }

    .plan-header {
      height: 100%;
      background-color: $darkBlue;
      color: $white;
      display: grid;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: $size28;
      border-left: 1px solid black;

      & > i {
        color: $trueOrange;
        font-size: $iconSizeMedium;
        margin-bottom: emCalc(24, 24);
      }
      & > .title {
        min-height: $newFontSize40;
        h2 {
          color: $white;
          font-size: $newFontSize20;
          font-weight: bold;
        }
      }

      & > .desc {
        color: $niceBlue;
        margin-top: $size28;
      }
    }

    .description-subheader {
      height: 100%;
      background-color: $niceBlue;
      color: $white;
      padding: $size28 $size12;
      & > div {
        display: grid;
        grid-template-columns: min-content 1fr;
        align-items: center;
        i {
          width: $size20;
          text-align: center;
          font-size: $iconSize;
          margin-right: $size6;
        }
        span {
          font-size: $newFontSize14;
          font-weight: bold;
        }
      }
    }

    .plan-subheader {
      height: 100%;
      background-color: $niceBlue;
      color: $white;
      display: grid;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: $size28;
      border-left: 1px solid $tealBlueTwo;

      .note {
        min-height: $newFontSize40;
        display: grid;
        align-items: end;

        &.center{
          align-items: center;
        }

        h3 {
          font-size: $newFontSize20;
        }

        &.alt {
          display: grid;
          grid-template-columns: 1fr 1fr;
          align-items: end;

          h3 {
            justify-self: end;
          }

          span {
            line-height: $newFontSize20;
            font-size: emCalc(16, 13);
            justify-self: start;
            padding-left: $size8;
          }
        }
      }

      .btn {
        font-size: $newFontSize12;
        font-weight: normal;
      }
    }

    .description-item {
      height: 100%;
      background-color: $lightGray;
      align-items: center;
      display: grid;
      grid-template-columns: min-content 1fr;
      padding: 0 $size12;

      &:not(:last-of-type) {
        border-bottom: 1px solid $borderGray;
      }

      i {
        width: $size20;
        text-align: center;
        font-size: $iconSize;
        margin-right: $size6;
        color: $niceBlue;
      }

      span {
        font-size: $newFontSize14;
        font-weight: bold;
        color: $darkBlue;
      }

    }

    .plan-item {
      height: 100%;
      background-color: $white;
      align-items: center;
      text-align: center;
      display: grid;
      grid-template-columns: 1fr;
      border-left: 1px solid $borderGray;
      color: $warmGrey;
      font-size: $newFontSize14;

      &:not(:last-of-type) {
        border-bottom: 1px solid $borderGray;
      }
    }

    .description-footer{
      height: 100%;
      background-color: $darkBlue;
      color: $white;
      padding: 0 $size12;
      display: grid;
      align-items: center;
      & > div {
        display: grid;
        align-items: center;
      }
    }

    .plan-footer {
      height: 100%;
      background-color: $darkBlue;
      color: $white;
      display: grid;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-left: 1px solid black;

      .crossed{
        text-decoration:line-through;
        margin-right: $size5;
      }
    }
  }

  .btn {
    margin-top: $size40;
    i{
      font-size: $iconSize;
    }
  }
}


