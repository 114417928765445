.feedback-list {
  .feedback-list-content {
    padding-top: $size70;
    @include upToPhoneLarge {
      padding-top: $size20;
    }

    .card {
      cursor: pointer;

      .card-header-icon {
        &.growth {
          background-color: $growthColor;
        }

        &.culture {
          background-color: $cultureColor;
        }

        &.relationship {
          background-color: $relationshipColor;
        }

        &.facts {
          background-color: $factsColor;
        }

        &.recommendation {
          background-color: $recommendationColor;
        }

        &.statement {
          background-color: $statementColor;
        }
      }

      .card-header {
        .feedback-item-status {
          display: grid;
          grid-auto-rows: min-content;
          grid-auto-columns: min-content;
          grid-auto-flow: column;

          grid-gap: $size20;
          align-self: center;
          justify-self: center;
          align-items: center;
          justify-items: center;
          padding-right: $size20;

          span.unreadCount {
            color: $white;
            background-color: $groundBlue;
            border-radius: 50%;
            font-size: $newFontSize20;
            font-weight: bold;
            line-height: emCalc(20, 50);
            text-align: center;
            width: emCalc(20, 50);
            height: emCalc(20, 50);
          }

          i {
            font-size: $newFontSize30;
          }

          i.isPositive {
            color: $trueGreen;
          }

          i.isNegative {
            color: $trueOrange;
          }
        }
      }

      .card-content {
        h3 {
          font-size: $newFontSize24;
          font-weight: bold;
          margin-bottom: emCalc(24, 20);
        }

        p {
          font-size: $newFontSize15;
          color: $warmGrey;
          padding-bottom: emCalc(15, 20);
          border-bottom: emCalc(15, 0.5) solid $trueGray;
          line-height: 1.5;
        }
      }

      .card-footer {
        &.feedback-item-footer {
          display: grid;
          grid-template-columns: 1fr max-content;
          align-items: center;
          width: 100%;
          padding: 0 $size20 $size20 $size20;

          @include upToPhoneLarge {
            grid-template-columns: 1fr;
          }

          .pills {
            @include upToPhoneLarge {
              text-align: center;
            }
            .pill {
              margin-right: emCalc(14, 20);
              background-color: $tealBlueTwo;
              @include upToPhoneLarge {
                margin-bottom: 1.42857em;
              }

              &.important {
                background-color: $trueOrange;
              }

              &.grey {
                background-color: $warmGrey;
              }
            }
          }
        }
      }
    }
  }
  .feedback-filter-items {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax($size160, 1fr));
    grid-gap: $size20;
    justify-content: space-evenly;

    @include upToPhoneLarge {
      grid-template-columns: 1fr;
    }
  }
}