/*.sliderWave.red {*/
/*background-image:url('../../../img/slider-wave-front-red.svg'),url('../../../img/slider-wave-back-red.svg') !important;*/
/*}*/

/*.sliderWave.orange {*/
/*background-image:url('../../../img/slider-wave-front.svg'),url('../../../img/slider-wave-back.svg') !important;;*/
/*}*/

/*.sliderWave.green {*/
/*background-image:url('../../../img/slider-wave-front-green.svg'),url('../../../img/slider-wave-back-green.svg') !important;;*/
/*}*/

.margin {
    margin: 4.4vw;
}

.editUserGrid {
    display: flex;
    flex-direction: column;
}

.editUserGrid > div:last-child {
    flex-grow: 1;
}

.teamEditContent {
    display: grid;
    grid-template-rows: repeat(3, min-content) 1fr;
}

.teamUserListContent {
    display: grid;
    grid-template-rows: min-content 1fr;
}

.teamUserSelectContent {
    display: grid;
    grid-template-rows: min-content 1fr;
}

.loading-modal {
    position: fixed;
    left: 0;
    top: 0;

    display: grid;
    background: rgba(0, 157, 182, 0.7);
    min-height: 100%;
    min-width: 100%;
    z-index: 10000;
    color: white;
    align-items: center;
    justify-items: center;
}

/*.loading-modal .loading-modal-content {*/
    /*align-self: center;*/
    /*justify-self: center;*/
    /*color: white;*/
/*}*/