.form-input-wrap {
  position: relative;
  font-size: 1rem; // dont scale inputs

  display: grid;
  align-items: center;

  .save-icon {
    color: $trueOrange !important;
  }

  .license-lock-icon {
    position: absolute;
    width: 100%;
  }

  .form-input {
    width: 100%;
    padding: emCalc(18, 18) emCalc(18, 32) emCalc(18, 18) emCalc(18, 20);
    background-color: $lightGray;
    color: $darkBlue;
    font-family: $roboto;
    font-size: $newFontSize18;
    border-radius: emCalc(18, 80);
    font-weight: normal;
    border: none;
    outline: none;
    box-shadow: none;

    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;

    @include upToPhoneLarge {
      font-size: 0.875rem;
      padding: emCalc(14, 13);
    }

    &:before,
    &:after,
    &:focus {
      content: '';
      content: none;
      border: none;
      outline: none;
      box-shadow: none;
    }

    @include placeholder {
      color: $darkGray;
      font-weight: 400;
      opacity: 1;
    }

    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &.error {
    i {
      color: $white !important;
    }

    .form-input {
      background: linear-gradient(to right, $trueRedTrans, transparent) !important;
      color: $white !important;
      @include placeholder {
        color: $white !important;
      }
    }
  }

  .errorBox {
    font-size: $newFontSize13;
    margin-left: emCalc(13, 10);
    color: $trueRed;
    padding: emCalc(13, 10);
    z-index: 10;
  }
}

.form-input-dropdown {
  position: relative;

  & > i {
    position: absolute;
    color: $darkGray;
    top: 50%;
    transform: translate(-50%, -50%);
    right: $size16;
    font-size: $newFontSize16;
    line-height: 0;
  }

  &.blue {
    input {
      background-color: $lightBlueTrans;
      color: $white;
      @include placeholder {
        color: $white;
      }
    }

    i {
      color: $white;
    }
  }

  .form-input {
    cursor: pointer;
  }
}

label.input-select-label {
  display: block;
  color: $white;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0 0 $size12 $size20;
}

.form-input-wrap.input-gender {
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  align-items: center;
  justify-items: center;

  & > span {
    color: $darkGray;
  }
}

.input-gender {
  i {
    position: absolute;
    color: $darkGray;
    font-size: $size30;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
  }

}

.input-check-box {
  display: grid;
  grid-template-areas: "checkbox label";
  grid-auto-flow: column;
  grid-auto-columns: auto;
  justify-content: start;
  grid-row-gap: $size14;
  grid-column-gap: $size14;
  align-items: center;
  justify-items: center;
  cursor: pointer;

  & > label {
    grid-area: checkbox;

    display: grid;
    width: $size60;
    height: $size60;
    border-radius: $size80;
    background-color: $veryLightGrey3;
    align-items: center;
    justify-items: center;
    cursor: pointer;

    @include upToPhoneLarge {
      width: $size40;
      height: $size40;
    }

    & > input {
      border: 0;
      width: $size1;
      height: $size1;
      clip: rect(0 0 0 0);
      overflow: hidden;
      padding: 0;
      position: absolute;
    }

    & > i {
      position: relative;
      top: unset;
      right: unset;
      color: $darkGray;
      transform: translate(0, 0);
      display: none;
    }
  }

  & > span {
    grid-area: label;
    color: $warmGrey;
  }

  &.selected {
    i {
      display: block;
      color: $darkBlue;
    }

    &.multi-select{
      i {
        color: $trueBlue;
      }

      label {
        background-color: $darkBlue;
      }
    }
  }

  input:focus + i {
    font-weight: bold;
  }
}

.multi-selection-list {
  display: grid;

  .input-check-box {
    justify-content: space-between;
    grid-template-areas: "label checkbox";
    padding: $size12 0;
    border-bottom: $size1 solid $veryLightGrey2;

    & > label {
      width: $size35;
      height: $size35;
    }

    & > span {
      font-weight: bold;
    }
  }

  .input-check-box:last-child {
    border-bottom: 0;
  }
}

.dynamic-textarea {
  position: relative;
  i {
    position: absolute;
    bottom: $size15;
    right: $size15;
  }
}