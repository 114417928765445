@mixin score-template($width) {
    $width-inner: floor($width - ($width * 0.06));
    $width-innerX2: $width-inner * 2;

    .score-container {
        width: $width;
        padding: 0;
        align-self: center;
        margin: 0 auto;

        .score-outer-circle {
            position: relative;
            background-color: $niceBlue;
            border-radius: 100%;
            width: $width;
            height: $width;

            .score-inner-circle {
                overflow: hidden;
                position: absolute;
                top: 50%;
                left: 50%;
                width: $width-inner;
                height: $width-inner;
                background-color: $darkBlue;
                transform: translate(-50%, -50%);
                border-radius: 100%;

                .score-inner-circle-content {
                    font-size: calc(#{$width} * 0.58);
                    font-weight: 700;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    color: $trueOrange;
                    transform: translate3d(-50%, -50%, 0);
                    line-height: 1;
                    z-index: 10;
                }
            }
        }
    }
}

.score-survey {
    @include score-template(192px);

    //@include upToPhoneLarge {
    //    @include score-template(150px);
    //}
}

.score-company{
    @include score-template(192px);

    //@include upToPhoneLarge {
    //    @include score-template(150px);
    //}
}

