.report-page {

  // hack !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! ;)


  .value-with-tendency {
    align-self: center;

    display: grid;
    grid-auto-columns: max-content;
    grid-auto-flow: column;
    grid-column-gap: $size15;
    align-items: center;

    span {
      font-size: $newFontSize40;
      font-weight: bold;
    }

    i {
      font-size: $newFontSize20;
    }
  }


  .header {
    position: relative;
    display: grid;
    grid-template-areas: //
            "title title" //
            "score tabs" //
            "score none"; //
    grid-template-rows: min-content 1fr 1fr;
    grid-template-columns: auto 1fr;
    margin-bottom: $size50;

    .background-color-pine-green {
      grid-row: 1/3;
      grid-column: 1/-1;
      position: absolute;
      left: -1000px; // leave PX
      right: -1000px; // leave PX
      top: 0;
      bottom: 0;
      background-color: $pineGreen;
      z-index: -1;
    }

    .background-color-dark-slate-blue {
      grid-row: 3/4;
      grid-column: 1/-1;
      position: absolute;
      left: -1000px; // leave PX
      right: -1000px; // leave PX
      top: 0;
      bottom: 0;
      background-color: $darkSlateBlue;
      z-index: -1;

      @include upToTablet {
        display: none;
      }
    }

    .appContent-header {
      grid-row: 1;
      grid-column: 1/-1;
    }

    .tabs {
      grid-area: tabs;
      align-self: end;
      justify-self: end;

      display: grid;
      grid-template-columns: repeat(5, min-content);
      grid-column-gap: $size3;
      z-index: 1;

      @include upToTablet {
        display: none;
      }

      .license-lock-icon {
        grid-column: 2/6;
        grid-row: 1/2;
      }

      .tab {
        cursor: pointer;
        display: grid;
        grid-template-columns: min-content 1fr;
        align-items: center;
        justify-items: center;
        border-radius: emCalc(16, 10) emCalc(16, 10) 0 0;
        padding: emCalc(16, 20);
        white-space: nowrap;
        text-transform: uppercase;
        font-size: $newFontSize16;
        font-weight: bold;
        background-color: $darkSlateBlue;
        color: $white;

        &.tab-default {
          background-color: $trueBlue;
          grid-row: 1/2;
        }

        &.tab-growth {
          grid-column: 2/3;
          grid-row: 1/2;
          background-color: $growthColor;
        }

        &.tab-culture {
          grid-column: 3/4;
          grid-row: 1/2;
          background-color: $cultureColor;
        }

        &.tab-relationship {
          grid-column: 4/5;
          grid-row: 1/2;
          background-color: $relationshipColor;
        }

        &.tab-facts {
          grid-column: 5/6;
          grid-row: 1/2;
          background-color: $factsColor;
        }

        i {
          display: none;
        }

        &:hover, &.active {
          background-color: $darkSlateBlue;
          color: $white;
        }
      }
    }

    .filter-row {
      grid-area: none;
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
      align-items: center;

      @include upToTablet {
        display: none;
      }

      .value-with-tendency {
        color: $white;
        font-size: $newFontSize20;
      }

      h4 {
        margin-top: $size5;
        color: $white;
        font-weight: bold;
        text-transform: uppercase;
        font-size: $newFontSize20;

        button {
          font-size: 1rem; // reset button font-size
          margin-left: emCalc(16, 16);
        }
      }

      .form-input-wrap {
        justify-self: end;

        .form-input-dropdown {
          input {
            background: $darkBlue;
            text-transform: uppercase;
            font-weight: bold;
          }

          i {
            color: $niceBlue;
          }
        }
      }
    }
  }


  .category-list {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: $size60;
    grid-row-gap: $size20;
    @include upToPhoneLandscape {
      grid-template-columns: 1fr;
    }
  }

  .button-row {
    margin: $size50 0;

    @include upToPhoneLarge {
      margin: $size20 0;
    }
  }
}


.report-card {
  // this should be a button but chrome cannot display: grid in a <button/>
  //@include reset-button;
  background: $darkSlateBlue;
  padding: $size13 $size18 $size13 $size20;
  border-radius: $size10;
  cursor: pointer;
  color: $white;

  display: grid;
  grid-template-columns: 1fr min-content;
  grid-row-gap: $size10;
  transition: .75s;

  @include upToPhoneLarge {
    font-size: $newFontSize14;
  }

  &:hover {
    background-color: $darkBlue;
  }

  .title {
    h4 {
      font-weight: bold;
      text-transform: uppercase;
      font-size: $newFontSize20;

      button {
        font-size: 1rem; // reset button font-size
        margin-left: emCalc(16, 16);
      }
    }
  }

  .progress-bar-wrap {
    background-color: black;
    border-radius: $size80;
    grid-column: 1/-1;
    height: $size6;
    overflow: hidden;

    .progress-bar {
      height: 100%;

      &.progress-bar-red {
        background-color: $trueRed;
      }

      &.progress-bar-orange {
        background-color: $trueOrange;
      }

      &.progress-bar-green {
        background-color: $trueGreen;
      }
    }
  }

  &.light {
    background-color: unset;
    border-radius: 0;
    border-bottom: $size1 solid $niceBlue;
    padding: $size13 0 $size10 0;

    .title {
      align-self: center;

      i {
        color: $darkSlateBlue;
      }
    }

    .progress-bar-wrap {
      display: none;
    }
  }
}

.appContent-header-subtitle {
  .breadcrumb {
    li {
      display: inline-block;
      color: $niceBlue;

      &:not(:last-child)::after {
        padding: 0 emCalc(16, 10);
        content: '/';
      }

      a {
        color: $niceBlue;
        text-transform: uppercase;

        h3 {
          color: $niceBlue;
        }
      }
    }

  }
}

.report-score {
  grid-area: score;
  justify-self: left;

  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 10fr 1fr;

  @include upToTablet {
    justify-self: center;
    grid-area: 2/1/-1/-1;
  }

  .new-wave {
    grid-row: 1/2;
    grid-column: 1/3;

    @include upToPhoneLarge {
      width: emCalc(12, 180);
      height: emCalc(12, 180);
      font-size: $newFontSize10;
      span {
        font-size: emCalc(10, 60);
      }
    }
  }

  .extended-row {
    grid-row: 1/3;
    grid-column: 1/3;
    display: none;
    align-self: end;
    grid-template-columns: 1fr 1fr;
    justify-items: center;

    @include upToTablet {
      display: grid;
    }

    @include upToPhoneLarge {
      font-size: $newFontSize10;
    }

    .btn, .form-input-wrap {
      font-size: unset;
    }

    .form-input-dropdown .btn {
      width: $size87;
      height: $size87;

      span {
        display: none;
      }

      i {
        margin: 0;
        font-size: $newFontSize24;
      }
    }

    .small-value-with-tendency {
      position: relative;
      display: grid;
      background: $darkBlue;
      justify-items: center;
      align-items: center;
      padding: 0;

      width: $size87;
      height: $size87;

      i {
        margin: 0;
        color: $white;
        font-size: $newFontSize12;
        position: absolute;
      }

      .fa-caret-up {
        top: 10%;
      }

      .fa-caret-down {
        bottom: 10%;
      }

      span {
        font-size: $newFontSize32;
        //font-weight: bold;
        //color: $white;
      }
    }
  }
}