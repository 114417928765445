ul.role-list {
  padding: $size26;
  background-color: $veryLightGrey;
  border-radius: $size10;
  @include upToPhoneLarge {
    font-size: $newFontSize14;
  }

  li {
    display: grid;
    grid-template-columns: 1fr min-content;
    padding: $size20 0;
    border-bottom: $size1 solid $veryLightGrey2;
    align-items: center;

    span {
      font-weight: bold;
      color: $warmGrey;
    }

    i {
      font-size: $newFontSize30;
      color: $pinkishGrey;
    }
  }

  li:first-child {
    padding-top: 0;

    h2 {
      font-size: $newFontSize24;
      font-weight: bold;
      color: $niceBlue;
    }

    i {
      font-size: $newFontSize30;
      color: $niceBlue;
    }
  }

  li:last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }

  .btn {
    font-size: 1em; // let buttons scale with everything for this
  }
}

