.appContent-header {
  width: 100%;
  padding: $size50 0;
  @include upToPhoneLarge {
    font-size: $newFontSize10;
    padding: emCalc(10, 20) 0;
  }
  text-align: center;

  .appContent-header-title {
    h1 {
      color: $white;
      font-weight: bold;
      text-transform: uppercase;
      overflow: hidden;


    }

    h1 span {
      font-size: $newFontSize45;
      display: inline-block;
      position: relative;

      a{
        margin-left: emCalc(45, 16);
        i{
          color: $darkSlateBlue;
          vertical-align: middle;
          font-size: emCalc(45, 30);
          margin-bottom: emCalc(45, 7.5);
        }
        @include hasHover {
          &:hover {
            i {
              color: $trueOrange;
            }
          }
        }
      }
    }

    h1 span:before,
    h1 span:after {
      content: "";
      position: absolute;
      border-bottom: emCalc(45, 0.5) solid $niceBlue;
      top: calc(50% - #{emCalc(45, 0.5)});
      width: emCalc(45, 600);
      height: emCalc(45, 0.5);

      @include upToPhoneLarge { // remove horizontal lines
        content: unset;
      }
    }

    h1 span:before {
      right: 100%;
      margin-right: emCalc(45, 16);
    }

    h1 span:after {
      left: 100%;
      margin-left: emCalc(45, 16);
    }

    &.has-info {
      h1 span {
        transform: translate(#{emCalc(45, 16)}, 0);
      }
    }
  }

  .appContent-header-subtitle {
    margin-top: $size20;
    text-align: center;

    h3 {
      color: $darkSlateBlue;
      font-size: $newFontSize16;
      font-weight: bold;
      text-transform: uppercase;
      @include upToPhoneLarge {
        font-size: emCalc(10, 14);
      }

      span {
        color: $white;
      }
    }
  }
}

