.surveySlider {
  padding: $size48;
  @include upToPhoneLarge {
    padding: $size20 0;
  }

  .surveySliderWrap {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;

    .new-wave {
      justify-self: center;

      @include upToPhoneLandscape {
        width: emCalc(12, 200);
        height: emCalc(12, 200);
        font-size: $newFontSize12;
        span {
          font-size: emCalc(12, 80);
        }
      }

      @include upToPhoneLarge {
        width: emCalc(10, 150);
        height: emCalc(10, 150);
        font-size: $newFontSize10;

        span {
          font-size: emCalc(10, 60);
        }
      }
    }

    .sliderBar {
      margin-top: $size48;
      @include upToPhoneLarge {
        margin-top: $size20;
      }
      text-align: center;
      -webkit-tap-highlight-color: transparent;

      .rangeslider-horizontal {
        position: relative;
        display: inline-block;
        width: calc(100% - #{$size38});
        height: $size16;
        background-color: $niceBlue;
        border-radius: $size8;
        box-shadow: none !important;

        .rangeslider__fill {
          height: 100%;
          background-color: transparent !important;
          border-radius: $size8;
          top: 0;
          box-shadow: none !important;
        }

        .rangeslider__handle {
          &::after {
            content: '';
            top: 50%;
            left: 50%;
            width: 50%;
            height: 100%;
            background-color: transparent;
            background-image: url('../../../img/icons-slider-arrows.svg');
            background-size: 100%;
            background-position: center center;
            background-repeat: no-repeat;
            transform: translate(-50%, -50%);
            border-radius: 0 !important;
            border: none !important;
            box-shadow: none !important;
          }

          @include hasHover {
            &:hover {
              transform: translate(-50%, -50%) scale(1.2);
            }
          }

          width: $size55;
          height: $size55;
          background-color: $trueOrange;
          border-radius: 100%;
          border: none !important;
          box-shadow: none !important;
          //transition: ease .5s;
          z-index: 30;
        }

        ul.rangeslider__labels {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          transform: translate(-50%, -50%);

          li.rangeslider__label-item:first-child {
            &::before {
              content: '';
              position: absolute;
              top: 50%;
              right: 50%;
              width: $size48;
              height: $size48;
              background-image: url('../../../img/icons-slider-happy.svg');
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
              transform: translate(50%, -50%);
              z-index: 10;
            }

            position: absolute;
            top: 50%;
            left: initial !important;
            right: 0 !important;
            width: $size38;
            height: $size38;
            transform: translate(50%,
                    -50%);
          }

          li.rangeslider__label-item:last-child {
            &::before {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              width: $size48;
              height: $size48;
              background-image: url('../../../img/icons-slider-sad.svg');
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
              transform: translate(-50%, -50%);
              z-index: 10;
            }

            position: absolute;
            top: 50%;
            right: initial !important;
            left: 0 !important;
            transform: translate(-50%,
                    -50%);
          }
        }
      }
    }
  }


}
