.steps-circle {
  width: emCalc(24, 95);
  height: emCalc(24, 95);
  font-size: $newFontSize24;
  border-radius: 50%;
  border: emCalc(24, 5) solid $niceBlue;
  background: $darkBlue;
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-column-gap: emCalc(24, 5);
  justify-content: center;
  align-content: center;

  span {
    color: $trueBlue;
  }

  span:first-child {
    color: $white;
  }
}

