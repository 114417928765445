.card {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: $size48;

  .card-body {
    background-color: $white;
    border-radius: $elBorderRadius;
    overflow: hidden;
    height: 100%;

    & > .card-image {
      box-shadow: none;
      -webkit-box-shadow: none;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-color: $darkBlue;
      min-height: $size437;
      @include upToPhoneLandscape {
        min-height: $size300;
      }
      @include upToPhoneLarge {
        min-height: $size200;
      }
    }

    & > .card-header {
      width: 100%;
      display: grid;
      grid-template-columns: $size98 1fr min-content;
      min-height: $size78;
      background-color: $darkBlue;
      @include upToPhoneLandscape {
        min-height: 0;
        font-size: $newFontSize13;
      }

      &.small {
        grid-template-columns: $size78 1fr min-content;
        min-height: $size78;
        @include upToPhoneLarge {
          min-height: 0;
        }
      }

      .card-header-icon {
        background-color: $niceBlue;
        height: 100%;
        padding: $size10;
        align-self: center;
        width: 100%;
        display: grid;

        i {
          color: $white;
          font-size: $newFontSize30;
          align-self: center;
          justify-self: center;
        }
      }

      .card-header-title {
        display: grid;
        grid-row-gap: $size16;
        align-self: center;
        align-items: center;
        color: $white;
        padding: $size20 $size24;
        grid-template-columns: 1fr max-content;

        h2 {
          font-size: $newFontSize24;
          text-transform: uppercase;
          font-weight: bold;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        h3 {
          font-size: $newFontSize18;
          color: $warmGrey;

          div{
            display: inline-block;
          }

          span {
            color: $white;
          }
        }

        &.two-row {
          grid-template-rows: max-content max-content;
          grid-template-columns: 1fr max-content;
          grid-template-areas: "title btn" "date btn";

          h2 {
            grid-area: title;
          }

          h3 {
            grid-area: date;
          }

          & > button, & > div {
            grid-area: btn;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .btn-group {
          .btn {
            margin-left: $size24;
            @include upToPhoneLarge {
              margin-left: $size8;
            }
          }
        }

        .btn {
          span {
            @include upToPhoneLarge {
              display: none;
            }
          }
        }
      }
    }

    & > .card-content {
      padding: $size50;
      @include upToPhoneLandscape {
        font-size: $newFontSize14;
        padding: $size20;
      }

      &.padding-s {
        padding: $size20;
      }

      &.form-layout {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: $size46;
        grid-row-gap: $size40;
        @include upToPhoneLarge {
          grid-template-columns: 1fr;
          grid-row-gap: $size20;
        }

        .input-wrap-two {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: $size13;
        }
      }
    }

    & > .card-footer {
      padding: 0 $size50 $size50 $size50;
      @include upToPhoneLandscape {
        padding: 0 $size20 $size20 $size20;
        font-size: $newFontSize14;
      }
    }
  }

  &::after {
    content: "";
    position: absolute;
    //z-index: -1;
    height: $size16;
    top: 100%;
    left: 50%;
    width: 95%;
    background-color: $cardBlueBottom;
    border-radius: 0 0 $elBorderRadius $elBorderRadius;
    transform: translate(-50%, 0);
    animation: listOut1 1.25s ease;
  }

  &::before {
    content: "";
    position: absolute;
    //z-index: -2;
    height: $size32;
    top: calc(100%);
    left: 50%;
    width: 90%;
    background-color: $cardBlueBottomLight;
    border-radius: 0 0 $elBorderRadius $elBorderRadius;
    transform: translate(-50%, 0);
    animation: listOut2 1.25s ease;
  }

  &.no-lines{
    &:after,&:before{
      content: unset;
    }
  }
}