.notification-manager-list {
  position: absolute;
  top: $size84;
  right: $size24;
  @include upToPhoneLarge {
    justify-items: center;
    right: 0;
    width: 100%;

  }

  display: grid;
  grid-row-gap: $size10;
}

.notification-box {
  padding: 0 $size20;

  .info-box-body {
    max-width: $size387;
    width: 100%;
  }

  .info-box-header {
    background-color: $trueOrange;
    i {
      color: $white;
    }
  }

  .info-box-content {
    p {
      color: $warmGrey;
      font-size: $newFontSize14;
    }
  }
}