.info-box {
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  width: 100%;
  height: 100%;
  background: $trueBlueTrans;
  z-index: 1000;
  padding: $size20;

  align-items: center;
  justify-items: center;
}

.info-box-body {
  border-radius: $size10;
  overflow: hidden;
  background-color: $white;
  max-width: $size500;
  width: 100%;
}

.info-box-header {
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  justify-items: center;
  align-items: center;
  background-color: $darkBlue;
  min-height: $size50;

  & > i:first-child {
    color: $trueBlue;
    font-size: $newFontSize24;
    padding: 0 emCalc(24, 16);
  }

  & > h2 {
    justify-self: left;
    font-size: $newFontSize18;
    font-weight: bold;
    color: $white;
    text-transform: uppercase;
  }

  .btn.plain:last-child {
    padding: 0 $size16;
  }

  .btn i:last-child {
    color: $white;
    font-size: $newFontSize24;
  }
}

.info-box-content {
  max-height: 70vh;
  padding: $size20;
  overflow: auto;

  p {
    font-size: $newFontSize13;
    line-height: 1.5;
    color: $warmGrey;
  }
}

.info-box-footer {
  display: grid;
  grid-template-columns: min-content min-content;
  grid-template-areas: "left right";
  justify-content: space-between;

  .btn {
    background: $veryLightGrey3;
    width: $size55;
    height: $size55;

    i {
      color: $warmGrey;
      font-size: $newFontSize24;
    }
    @include hasHover {
      &:hover {
        background-color: $darkBlue;
        i {
          color: $trueBlue !important;
        }
      }
    }
  }

  .btn:first-child {
    grid-area: left;
    border-radius: 0 $size10 0 $size10;
  }

  .btn:last-child {
    grid-area: right;
    border-radius: $size10 0 $size10 0;
  }
}