.soluting-item {
  .card {
    margin-bottom: $size74;
    .card-body {
      .card-header {
        grid-template-columns: auto;
        background-color: $white;
        padding: $size50 $size50 0 $size50;
        @include upToPhoneLandscape {
          padding: $size20 $size20 0 $size20;
        }

        h2 {
          font-size: $newFontSize24;
          font-weight: bold;
          color: $darkBlue;
          line-height: 1.5;
        }

        h3 {
          font-size: $newFontSize30;
          font-weight: bold;
          color: $darkBlue;
          line-height: 1.5;
          padding-bottom: emCalc(30, 20);
          border-bottom: emCalc(30, 1) solid $lightGray;
        }

        p {
          font-weight: bold;
          color: $darkBlue;
          padding-bottom:$size20;
        }
      }
      .card-content {
        & > p {
          margin-bottom: $size25;
          color: $warmGrey;
          line-height: 1.5;
        }

        .soluting-lessons {
          margin-top: $size40;

          .lesson-item {
            padding: $size20;
            background-color: $lightGray;
            margin-bottom: $size20;
            border-radius: $elBorderRadius;
            display: grid;
            grid-template-columns: min-content 1fr;
            align-items: center;
            cursor: pointer;

            .lesson-progress {
              height: $size54;
              width: $size54;
              border-radius: 50%;
              background-color: $white;
              margin-right: $size20;
              position: relative;

              i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: $newFontSize30;
                color: $lightGray;
              }
            }

            .lesson-title {
              h4 {
                font-size: $newFontSize24;
                font-weight: bold;
                line-height: 1.3;
                color: $darkBlue;
              }
              p {
                color: $warmGrey;
                font-size: $newFontSize18;
                font-weight: bold;
                line-height: 1.4;
              }
            }
          }
        }
      }
    }

    &.facts {
      .card-body {
        .card-header > h2,
        .card-content .soluting-lessons .lesson-item .lesson-title > h4,
        .card-content .soluting-lessons .lesson-item .lesson-progress > i.completed
        {
          color: $factsColor;
        }
      }
    }
    &.relationship {
      .card-body {
        .card-header > h2,
        .card-content .soluting-lessons .lesson-item .lesson-title > h4,
        .card-content .soluting-lessons .lesson-item .lesson-progress > i.completed{
          color: $relationshipColor;
        }
      }
    }
    &.culture {
      .card-body {
        .card-header > h2,
        .card-content .soluting-lessons .lesson-item .lesson-title > h4,
        .card-content .soluting-lessons .lesson-item .lesson-progress > i.completed{
          color: $cultureColor;
        }
      }
    }
    &.growth {
      .card-body {
        .card-header > h2,
        .card-content .soluting-lessons .lesson-item .lesson-title > h4,
        .card-content .soluting-lessons .lesson-item .lesson-progress > i.completed{
          color: $growthColor;
        }
      }
    }
    &.recommendation {
      .card-body {
        .card-header > h2,
        .card-content .soluting-lessons .lesson-item .lesson-title > h4,
        .card-content .soluting-lessons .lesson-item .lesson-progress > i.completed{
          color: $recommendationColor;
        }
      }
    }
  }
}