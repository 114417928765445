.input-check-box-anonymous-reverse{
  direction: rtl;
  .input-check-box-anonymous{
    .user-name{
      text-align: left;
    }
  }
}

.input-check-box-anonymous {
  display: grid;
  grid-template-columns: min-content min-content;
  align-items: center;
  grid-column-gap: $size12;

  @include upToPhoneLarge {
    grid-template-columns: max-content min-content;
  }

  &.hidden{
    display:none;
  }

  &.disabled {
    i {
      color: $lightGrayTrans;
    }

    .switch .slider:before {
      background-color: $lightGrayTrans;
    }
  }

  .user-name{
    color: $niceBlue;
    font-size: $newFontSize20;
    transition: .4s;
    white-space: nowrap;
    font-weight: bold;
    @include upToPhoneLarge {
      white-space: pre-wrap;
    }
  }

  i {
    color: $niceBlue;
    font-size: $newFontSize30;
    transition: .4s;

    &.transparent {
      opacity: 0.2;
    }
  }

  .switch {
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    position: relative;
    display: inline-block;
    width: $size84;
    height: $size40;

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $pineGreen;
      border-radius: $size80;
      transition: .4s;
    }

    .slider:before {
      position: absolute;
      content: '';
      width: $size28;
      height: $size28;
      right: $size6;
      bottom: $size6;
      background-color: $niceBlue;
      transition: .4s;
      border-radius: $size80;
      opacity: 0.2;
    }

    input:checked + .slider {
      //background-color: #2196f3;
    }

    input:focus + .slider {
      box-shadow: 0 0 $size1 #2196f3;
    }

    input:checked + .slider:before {
      transform: translateX(-$size44);
      opacity: 1;

    }
  }
}
