html {
  height: 100%;
  font-size: $newFontSize16;
}

body {
  background-color: $tealBlueTwo;
  font-family: $roboto;
  height: 100%; // TODO - decide what to do about FullscreenAPI

  & > div.root{ //react app must have some root element inside body
    width: 100%;
    height: 100%;
  }
}

p {
  white-space: normal;
}

/* all other pages */
main {
  display: grid;
  position: relative;
  //grid-template-columns: 1fr $size960 1fr;
  grid-template-rows: min-content 1fr;
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-position: right bottom;
  background-repeat: no-repeat;

  /* signup page */
  &.bg-style1 {
    background-image: url('../../../img/bcg-desktop-signup.png');
    @include upToLargeDesktop {
      background-image: none;
    }
  }

  /* Login/SignUp Screen */
  &.bg-style2 {
    background-image: url('../../../img/bcg-desktop-login.png');
    @include upToLargeDesktop {
      background-image: none;
    }
  }

  .appHeader {
    width: 100%;
    grid-template-rows: min-content min-content;
    grid-column: 1/-1;

    .appHeaderWrap {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      transition: ease .5s;
      z-index: 11;
    }
  }

  .appContent {
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    display: grid;
    grid-template-columns: 1fr minmax(#{$size280}, #{$size1000}) 1fr;
    transition: 0.75s all ease-in-out;
    @include upToPhoneLarge {
      transition: unset;
    }

    &.nav-active{
      padding-left: $size320;
      transition: 0.75s all ease-in-out;
      @include upToPhoneLarge {
        transition: unset;
      }
    }

    .appContent-body {
      padding: 0  $size20 $size50 $size20;
      grid-column: 2/3;
    }
  }
}