.feedback-message {
  display: grid;
  grid-template-rows: min-content 1fr min-content;
}

.feedback-message-header {
  position: relative;
  @include upToPhoneLarge {
    font-size: $newFontSize12;
  }

  h1 {
    margin-bottom: emCalc(24, 24);
  }

  p {
    color: $white;
    font-size: $newFontSize20;
    margin-bottom: emCalc(20, 35);
  }

  div {
    display: grid;
    grid-template-columns: min-content max-content;
    justify-content: space-between;
  }

  .dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    padding: $size20 $size30;

    color: $white;
    background-color: $groundBlue;

    li {
      font-size: $newFontSize18;
      cursor: pointer;

      &:not(:first-child) {
        margin-top: emCalc(18, 20);
      }

      &:hover {
        color: $trueOrange;
      }

      &.disabled {
        color: $warmGrey;
      }
    }
  }
}

.feedback-message-content {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  grid-row-gap: $size20;
  align-content: start;
  padding-top: $size50;

  .message {
    width: 100%;
    display: grid;
    grid-template-areas: "left right";
    grid-template-columns: min-content 1fr;
    grid-column-gap: $size28;
    align-items: center;

    span.time {
      grid-area: left;
      color: $white;
      font-size: $newFontSize16;
      font-weight: bold;
    }

    .messageContent {
      grid-area: right;
      background-color: $tealBlueTwo;
      color: $white;
      padding: $size14;
      line-height: $size20;
      border-radius: $elBorderRadius;

      h5 {
        color: $trueBlue;
        font-size: $newFontSize15;
        font-weight: bold;
        margin-bottom: emCalc(15, 12);
      }

      p {
        font-size: $newFontSize15;
      }
    }

    &.user-current {
    }

    &.user-other {
      width: 100%;
      padding-right: 0;
      grid-template-columns: 1fr min-content;

      .time {
        grid-area: right;
      }

      .messageContent {
        grid-area: left;
        color: $warmGrey;
        background-color: $white;
      }
    }
  }

  .time-separator {
    display: flex;
    align-items: center;
    text-align: center;
    white-space: nowrap;

    span {
      padding: 0 emCalc(16, 30);
      color: $darkSlateBlue;
      font-size: $newFontSize16;
      font-weight: bold;
      text-transform: uppercase;
    }

    &::before,
    &::after {
      content: "";
      width: 100%;
      height: $size1;
      background-color: $darkSlateBlue;
    }
  }
}

.feedback-message-footer {
  background-color: $groundBlue;
  padding: $size28 $size24;
  margin-bottom: -$size50;

  .buttons {
    width: 100%;
    display: grid;
    grid-template-columns: max-content max-content;
    justify-content: space-between;
  }

  textarea {
    width: 100%;
    border: none;
    border-radius: $size16;
    padding: $size16;
    margin-bottom: $size30;
    font-size: $newFontSize14;
    font-family: $roboto;
    resize: none;
  }
}