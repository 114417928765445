m-0 {
  margin: 0 !important
}

.mt-0 {
  margin-top: 0 !important
}

.mr-0 {
  margin-right: 0 !important
}

.mb-0 {
  margin-bottom: 0 !important
}

.ml-0{
  margin-left: 0 !important
}

.m-1 {
  margin: $size20 !important
}

.mt-1 {
  margin-top: $size20 !important
}

.mr-1 {
  margin-right: $size20 !important
}

.mb-1 {
  margin-bottom: $size20 !important
}

.ml-1 {
  margin-left: $size20 !important
}

.m-2 {
  margin: $size40 !important
}

.mt-2 {
  margin-top: $size40 !important
}

.mr-2 {
  margin-right: $size40 !important
}

.mb-2 {
  margin-bottom: $size40 !important
}

.ml-2{
  margin-left: $size40 !important
}

.m-3 {
  margin: $size50 !important
}

.mt-3 {
  margin-top: $size50 !important
}

.mr-3 {
  margin-right: $size50 !important
}

.mb-3{
  margin-bottom: $size50 !important
}

.ml-3 {
  margin-left: $size50 !important
}



.p-0 {
  padding: 0 !important
}

.pt-0 {
  padding-top: 0 !important
}

.pr-0 {
  padding-right: 0 !important
}

.pb-0 {
  padding-bottom: 0 !important
}

.pl-0{
  padding-left: 0 !important
}

.p-1 {
  padding: $size20 !important
}

.pt-1 {
  padding-top: $size20 !important
}

.pr-1 {
  padding-right: $size20 !important
}

.pb-1 {
  padding-bottom: $size20 !important
}

.pl-1 {
  padding-left: $size20 !important
}

.p-2 {
  padding: $size40 !important
}

.pt-2 {
  padding-top: $size40 !important
}

.pr-2 {
  padding-right: $size40 !important
}

.pb-2 {
  padding-bottom: $size40 !important
}

.pl-2 {
  padding-left: $size40 !important
}

.p-3 {
  padding: $size50 !important
}

.pt-3 {
  padding-top: $size50 !important
}

.pr-3 {
  padding-right: $size50 !important
}

.pb-3 {
  padding-bottom: $size50 !important
}

.pl-3 {
  padding-left: $size50 !important
}
