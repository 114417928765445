/* Survey List */
.survey-list {
  .survey-list-header {
    text-align: center;
    margin-bottom: $size50;
    @include upToPhoneLarge {
      margin-bottom: $size20;
    }
    h2 {
      font-size: $newFontSize24;
      font-weight: bold;
      text-transform: uppercase;
      color: $white;
    }
  }

  .survey-list-items {
    margin-bottom: $size50;
    @include upToPhoneLarge {
      margin-bottom: $size20;
    }

    .survey-list-no-data{
      color:$white;
    }

    .survey-item {
      display: grid;
      grid-template-columns: $size98 1fr;
      border-radius: $elBorderRadius;
      overflow: hidden;
      margin-bottom: $size20;
      color: $darkBlue;
      @include upToPhoneLarge {
        font-size: $newFontSize10;
      }

      .survey-icon {
        position: relative;
        background-color: $darkBlue;
        align-items: center;

        i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: $newFontSize30;
          color: $white;

        }
      }

      .survey-content {
        display: grid;
        grid-template-columns: 1fr min-content;
        padding: $size20;
        background-color: $white;
        align-items: center;
        justify-content: center;

        .survey-title {
          font-size: $newFontSize24;
          h3 {
            color: $niceBlue;
            font-weight: bold;
            margin-bottom: emCalc(24, 12);
          }
          p {
            color: $warmGrey;
            span {
              color: $trueOrange;

              &.done{
                color:$trueGreen;
              }
              &.expired{
                color:$trueRed
              }
            }

          }
        }

        .survey-icons {
          display: grid;
          grid-template-columns: min-content min-content;
          grid-column-gap: $size16;
          text-align: center;

          i {
            font-size: $newFontSize30;
            color: $pinkishGrey;
            display: inline;

            &.active{
              color:$trueOrange;
            }

            &:last-of-type {
              margin-right: 0;
            }
          }
        }
      }
    }

    &.survey-past {
      .survey-item {
        cursor: unset;

        .survey-icon {
          background-color: $niceBlue;
        }

        .survey-content {
          background-color: $darkBlue;

          .survey-title {
            p {
              color: $deepTurquoise;
            }
          }

          .survey-icons {
            i {
              color: $tealBlueTwo;

              &.active{
                color:$trueOrange;
              }
            }
          }
        }


      }
    }
  }
}