.txtLeft {
    text-align: left;
}

.txtCenter {
    text-align: center;
}

.txtRight {
    text-align: right;
}

.clearfix {
    float: none;
    clear: both;
    width: 100%;
}