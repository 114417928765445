.dropdown-header {
  background-color: $groundBlue;
  padding: $size28 $size20;

  h1 {
    color: $niceBlue;
    font-weight: bold;
    font-size: $newFontSize24;
    text-transform: uppercase;
  }
}

.dropdown-content {
  overflow-y: auto;
  padding: $size20;
  background-color: $niceBlue;
}