.card.card-custom-open-question {
  margin-bottom: $size80;

  .card-body {
    & > .card-header {
      .card-header-icon {
        background-color: $trueOrange;
      }
      .card-header-title h2 {
        color: $trueOrange;
      }
    }
    & > .card-content {
      font-size: $newFontSize12;

      h3 {
        font-size: $newFontSize20;
        color: $niceBlue;
        line-height: $size24;
        font-weight: bold;
        margin-bottom: $size13;
      }

      p {
        border: none;
        padding: 0;
        font-size: $newFontSize16;
        line-height: $size24;
        color: $warmGrey;
      }

      textarea {
        resize: none;
        width: 100%;
        color: $darkBlue;
        font-family: Roboto, sans-serif;
        font-size: $newFontSize18;
        border: none;
        background-color: $lightGray;
        border-radius: $size10;
        padding: $size16;
      }
    }

    & > .card-footer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: $size20;

      button{
        white-space: nowrap;
      };

      @include upToPhoneLarge {
        grid-template-columns: 1fr;
        text-align: center;

        div:first-child{
          grid-row: 2;
        }
      }
    }
  }
}



/* COQ List */
.coq-list {
  .coq-list-header {
    text-align: center;
    margin-bottom: $size50;
    @include upToPhoneLarge {
      margin-bottom: $size20;
    }
    h2 {
      font-size: $newFontSize25;
      font-weight: bold;
      text-transform: uppercase;
      color: $white;

      button {
        margin-left: $size10;
        i {
          color: $darkBlue;
          font-size: $newFontSize24;
        }
      }
    }
  }

  .coq-list-items {
    margin-bottom: $size50;
    @include upToPhoneLarge {
      margin-bottom: $size20;
    }


    .coq-list-no-data{
      color:$white;
    }

    .coq-item {
      display: grid;
      grid-template-columns: $size98 1fr;
      border-radius: $elBorderRadius;
      overflow: hidden;
      margin-bottom: $size20;
      color: $darkBlue;
      @include upToPhoneLarge {
        font-size: $newFontSize10;
      }

      &.active {
        .coq-icon i {
          color: $trueGreen;
        }
      }

      &.next {
        .coq-icon i {
          color: $trueOrange;
        }
      }

      .coq-icon {
        position: relative;
        background-color: $darkBlue;
        align-items: center;

        i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: $newFontSize30;
          color: $white;
        }
      }

      .coq-content {
        display: grid;
        grid-template-columns: 1fr min-content;
        padding: $size25;
        background-color: $white;
        align-items: center;
        justify-content: center;

        .coq-title {
          font-size: $newFontSize20;
          h3 {
            color: $niceBlue;
            font-weight: bold;
          }
        }

        .coq-icons {
          display: grid;
          grid-template-columns: min-content min-content;
          grid-column-gap: $size20;
          text-align: center;

          i {
            font-size: $newFontSize24;
            color: $pinkishGrey;
            display: inline;

            &.active{
              color:$trueOrange;
            }

            &:last-of-type {
              margin-right: 0;
            }
          }
        }
      }
    }

    &.coq-past {
      .coq-item {
        cursor: unset;

        .coq-icon {
          background-color: $niceBlue;
        }

        .coq-content {
          background-color: $darkBlue;

          .coq-title {
            p {
              color: $deepTurquoise;
            }
          }

          .coq-icons {
            i {
              color: $tealBlueTwo;

              &.active{
                color:$trueOrange;
              }
            }
          }
        }
      }
    }
  }
}