.notification-dropdown {
  max-width: $size400;

  .notification-dropdown-header {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
  }

  .notification-dropdown-content {
    display: grid;
    grid-auto-flow: row;
    grid-row-gap: $size20;
  }
}

.notification-card.small {
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-column-gap: $size28;

  & > i {
    color: $white;
    font-size: $newFontSize30;
  }

  .notification-body {
    border-radius: $size10;
    background-color: $white;
    overflow: hidden;
    padding: $size20 $size16;

    .notification-title {
      display: grid;
      grid-auto-flow: column;
      justify-content: space-between;
      align-items: center;

      margin-bottom: $size15;

      color: $niceBlue;
      font-weight: bold;
      font-size: $newFontSize18;
      text-transform: uppercase;

      .notification-date {
        color: $warmGrey;
        font-size: $newFontSize13;
      }
    }

    .notification-content {
      color: $warmGrey;
      font-size: $newFontSize15;

      margin-bottom: $size15;
    }
  }

  &.read {
    .notification-body {
      background-color: $tealBlueTwo;
    }

    .notification-date, .notification-content, .notification-title {
      color: $white !important;
    }

    button {
      background-color: $niceBlue;
    }
  }
}

.card.notification-card.big {
  .card-header .card-header-title {
    h3 {
      color: $white;
    }

    .btn.blue {
      &:hover {
        background-color: $tealBlueTwo;
      }

      @include upToPhoneLarge {
        display: none;
      }
    }
  }
}

.notification-page {
  .notification-filter {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax($size200, auto));
    grid-gap: $size26;
  }

  .notification-list {
    padding-top: $size70;
    @include upToPhoneLarge {
      padding-top: $size20;
    }
  }
}