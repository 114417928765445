table.table {
  width: 100%;
  table-layout: fixed;

  td {
    padding:$size20 0;
    color: $warmGrey;
    background-color: $white;
    border-bottom: $size1 solid $lightGray;
    white-space: normal;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  td:first-child {
    font-weight: bold;
  }

  tr:first-child td {
    padding: 0 0 $size20 0;
  }

  tr:last-child td {
    padding: $size20 0 0 0;
    border-bottom: none;
  }

  tr:only-child td {
    padding: 0;
  }
}