.profiling-result {
  padding-bottom: $size50;

  .card {
    .card-body {

      .card-header {
        cursor: pointer;

        .card-header-icon {
          background-color: $niceBlue;
          border-bottom: $size1 solid #008297;
        }
        .card-header-title {
          padding: $size24;
          height: 100%;
          border-bottom: $size1 solid #001215;
        }

        .card-header-arrow {
          height: 100%;
          padding: $size24 0;
          align-self: center;
          border-bottom: $size1 solid #001215;
          i {
            font-size: $newFontSize24;
            color: $niceBlue;
            margin-right: emCalc(24, 24);
          }
        }

        &.no-border{
         & > *{
           border-bottom: unset;
         }
        }
      }

      .card-content {
        display: none;
        color: $warmGrey;
        line-height: 1.5;
        padding: $size50;
        &.active {
          display: block;
        }
        @include upToPhoneLandscape {
          padding: $size20;
        }

        h3 {
          color: $niceBlue;
          font-size: $newFontSize20;
          font-weight: bold;
        }

        p {
          margin-bottom: $size25;
          &:last-child{
            margin-bottom: 0;
          }
        }

        .motto-content {
          padding: emCalc(18, 24) emCalc(18, 30);
          background-color: $lightGray;
          border-radius: emCalc(18, 10);
          font-size: $newFontSize18;
          font-weight: bold;

          h3 {
            color: $trueOrange;
            font-style: italic;
            font-size: emCalc(18, 26);
            font-weight: normal;
          }
        }
      }
    }
  }

  .repeat-note{
    color: $white;
    margin-top: $size50;
    text-align: center;

    @include upToPhoneLandscape {
      margin-top: $size20;
    }
  }

  .footer-btn {
    margin-top: $size50;
    text-align: center;

    @include upToPhoneLandscape {
      margin-top: $size20;
    }

    .btn {
      i {
        margin-left: $size20
      }
    }
  }
}

.profiling-result-links{
  float: right;
  a{
    color:$white;
    font-size: emCalc(18, 26);
    font-weight: bold;
    cursor: pointer;

    &:hover{
      color:$trueOrange;
    }
  }
}