/* Header Navigation */
.nav {
  display: grid;
  padding: $size20;
  grid-template-columns: min-content min-content 1fr;
  background-color: $pineGreen;
  @include upToPhoneLarge {
    font-size: $newFontSize13;

    .nav-menu {
      font-size: 1rem; // reset to default size
    }
  }

  .nav-menu-btn {
    grid-row: 1;
    align-self: center;
  }

  .nav-logo {
    display: block;
    align-self: center;
    grid-row: 1;
    text-align: center;
    margin-left: $size20;

    img {
      width: $size122;
      @include upToPhoneSmall {
        width: $size100;
      }
    }
  }

  .nav-info {
    grid-row: 1;
    align-self: center;
    text-align: right;
    white-space: nowrap;

    & > button {
      margin-left: $size40;
      @include upToPhoneLarge {
        margin-left: $size20;
      }
    }
  }

  .nav-icon {
    position: relative;
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    line-height: 1;
    text-align: left;

    i {
      transition: 250ms ease;
      font-size: $newFontSize30;
      color: $trueBlue;
    }

    span {
      font-size: $newFontSize20;
      transition: 250ms ease;
      margin-left: emCalc(20, 10);
      vertical-align: top;
      color: $white;
      font-weight: bold;
      line-height: emCalc(20, 30);
    }

    @include hasHover {
      &:hover {
        i {
          color: $trueOrange;
        }
        span {
          color: $trueOrange;
        }
      }
    }

    &.has-notification::after {
      content: "";
      position: absolute;
      top: 0;
      right: -$size3;
      width: $size6;
      height: $size6;
      border-radius: 50%;
      background-color: $green;
    }
  }
}

/* Navigation Menu */
.nav-menu {
  position: fixed;
  top: 0;
  right: 100%;
  bottom: 0;
  width: 100%;
  background-color: $groundBlue;
  opacity: 0;
  z-index: 3;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transition: 0.75s all ease-in-out;
  transform: translateX(0);
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  max-width: $size320;
  height: 100%;

  &.navSlideIn{
    opacity: 1;
    transform: translateX(100%)
  }

  .nav-menu-header{
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    padding: 1.25em;

    @include upToPhoneLarge{
      grid-template-columns: 1fr min-content;
    }

    .nav-icon{
      @include upToPhoneLarge{
        display: none;
      }
    }

    .nav-logo{
      grid-row: auto;
      text-align: left;
      margin-left: $size20;
    }

    .nav-close {
      position: relative;
      background: transparent;
      border: none;
      cursor: pointer;
      line-height: 1;
      padding: 0;
      text-align: right;
      display: none;
      @include upToPhoneLarge{
        display: block;
      }

      i {
        font-size: $newFontSize30;
        color: $trueBlue;
      }
      @include hasHover {
        &:hover {
          & > i {
            color: $trueOrange;
          }
        }
      }
    }
  }

  .nav-menu-list {
    .nav-menu-item {
      a {
        position: relative;
        display: block;
        padding: emCalc(16, 14) emCalc(16, 28);
        font-size: $newFontSize16;
        font-weight: 500;
        text-transform: uppercase;
        border-radius: $menuBorderRadius;
        color: $white
      }

      i {
        font-size: $newFontSize20;
        min-width: emCalc(20, 24);
        text-align: center;
        color: $trueBlue;
        margin-left: emCalc(20, 10);

        &.arrow {
          float: right;
          margin-left: 0;
        }
      }

      span {
        margin-left: $size10;
      }

      &.current > a {
        color: $niceBlue;
        margin: 0 $size16;
        padding-left: $size12;

        i {
          color: $trueOrange;
        }
      }

      &.premium > a {
        background-color: $trueBlue;
        color: $white;
        margin: 0 $size16;
        padding-left: $size12;

        i {
          color: $white;
        }
      }

      &.sub-active > a {
        color: white;
      }

      .sub-menu {
        background-color: $pineGreen;

        .current:first-of-type {
          padding-top: 0.5rem;
        }

        .current:last-of-type {
          padding-bottom: 0.5rem;
        }
      }

      @include hasHover {
        &:not(.current):hover {
          & > a {
            color: $trueOrange;
            & > i {
              color: $trueOrange;
            }
          }
        }
      }
    }
  }

  .nav-menu-user-info {
    display: grid;
    grid-template-columns: min-content 1fr;
    align-items: center;
    padding: $size30;
    background-color: $darkBlue;
    padding-left: $size24;

    & > span.user-icon {
      width: emCalc(24, 52);
      height: emCalc(24, 52);
      border-radius: 50%;
      line-height: emCalc(24, 52);
      background-color: $trueBlue;
      color: $darkBlue;
      font-size: $newFontSize24;
      text-transform: uppercase;
      text-align: center;
    }

    & > .user-info {
      margin-left: $size14;
      overflow: hidden;

      & > h5.user-name {
        font-size: $newFontSize22;
        font-weight: 600;
        text-transform: uppercase;
        color: $white;
        margin-bottom: emCalc(22, 3);
      }

      span {
        font-size: $newFontSize12;
        font-weight: 600;
        text-transform: uppercase;
        color: $trueBlue
      }
    }
  }
}