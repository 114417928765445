$baseUnit: em;

$sizeModifier: 1;

@function pxToRem($pxSize) {
  $remSize: $pxSize / 16 * $sizeModifier;

  @return #{$remSize}$baseUnit;
}

$fontModifier: 1;

@function fontPxToRem($pxSize) {
  $remSize: $pxSize / 16 * $fontModifier;
  @return #{$remSize}$baseUnit;
}

@function emCalc($parentSize, $desiredSize) {
  @if $baseUnit == em {
    @return #{$desiredSize / $parentSize}$baseUnit;
  } @else {
    @return pxToRem($desiredSize);
  }
}

$size0_5: pxToRem(0.5);
$size1: pxToRem(1);
$size2: pxToRem(2);
$size3: pxToRem(3);
$size5: pxToRem(5);
$size6: pxToRem(6);
$size7: pxToRem(7);
$size8: pxToRem(8);
$size10: pxToRem(10);
$size11: pxToRem(11);
$size12: pxToRem(12);
$size13: pxToRem(13);
$size14: pxToRem(14);
$size15: pxToRem(15);
$size16: pxToRem(16);
$size18: pxToRem(18);
$size19: pxToRem(19);
$size20: pxToRem(20);
$size21: pxToRem(21);
$size24: pxToRem(24);
$size25: pxToRem(25);
$size26: pxToRem(26);
$size28: pxToRem(28);
$size30: pxToRem(30);
$size32: pxToRem(32);
$size35: pxToRem(35);
$size36: pxToRem(36);
$size38: pxToRem(38);
$size40: pxToRem(40);
$size42: pxToRem(42);
$size44: pxToRem(44);
$size46: pxToRem(46);
$size48: pxToRem(48);
$size50: pxToRem(50);
$size52: pxToRem(52);
$size54: pxToRem(54);
$size55: pxToRem(55);
$size58: pxToRem(58);
$size60: pxToRem(60);
$size65: pxToRem(65);
$size66: pxToRem(66);
$size70: pxToRem(70);
$size74: pxToRem(74);
$size78: pxToRem(78);
$size80: pxToRem(80);
$size84: pxToRem(84);
$size87: pxToRem(87);
$size90: pxToRem(90);
$size95: pxToRem(95);
$size98: pxToRem(98);
$size100: pxToRem(100);
$size113: pxToRem(113);
$size122: pxToRem(122);
$size140: pxToRem(140);
$size150: pxToRem(150);
$size160: pxToRem(160);
$size192: pxToRem(192);
$size200: pxToRem(200);
$size214: pxToRem(214);
$size216: pxToRem(216);
$size260: pxToRem(260);
$size270: pxToRem(270);
$size280: pxToRem(280);
$size300: pxToRem(300);
$size320: pxToRem(320);
$size321: pxToRem(321);
$size329: pxToRem(329);
$size330: pxToRem(330);
$size350: pxToRem(350);
$size360: pxToRem(360);
$size380: pxToRem(380);
$size387: pxToRem(387);
$size437: pxToRem(437);
$size400: pxToRem(400);
$size413: pxToRem(413);
$size450: pxToRem(450);
$size480: pxToRem(480);
$size500: pxToRem(500);
$size540: pxToRem(540);
$size576: pxToRem(576);
$size600: pxToRem(600);
$size768: pxToRem(768);
$size992: pxToRem(992);
$size1000: pxToRem(1000);
$size1200: pxToRem(1200);
$size1600: pxToRem(1600);

$newFontSize3: fontPxToRem(3);
$newFontSize5: fontPxToRem(5);
$newFontSize6: fontPxToRem(6);
$newFontSize8: fontPxToRem(8);
$newFontSize10: fontPxToRem(10);
$newFontSize11: fontPxToRem(11);
$newFontSize12: fontPxToRem(12);
$newFontSize13: fontPxToRem(13);
$newFontSize14: fontPxToRem(14);
$newFontSize15: fontPxToRem(15);
$newFontSize16: fontPxToRem(16);
$newFontSize18: fontPxToRem(18);
$newFontSize20: fontPxToRem(20);
$newFontSize21: fontPxToRem(21);
$newFontSize22: fontPxToRem(22);
$newFontSize24: fontPxToRem(24);
$newFontSize25: fontPxToRem(25); // recaptcha
$newFontSize26: fontPxToRem(26);

$newFontSize30: fontPxToRem(30);
$newFontSize32: fontPxToRem(32);
$newFontSize34: fontPxToRem(34);
$newFontSize36: fontPxToRem(36);


$newFontSize40: fontPxToRem(40);

$newFontSize45: fontPxToRem(45);
$newFontSize46: fontPxToRem(46);
$newFontSize48: fontPxToRem(48);
$newFontSize50: fontPxToRem(50);
$newFontSize54: fontPxToRem(54);
$newFontSize58: fontPxToRem(58);
$newFontSize60: fontPxToRem(60);
$newFontSize64: fontPxToRem(64);
$newFontSize65: fontPxToRem(65);
$newFontSize66: fontPxToRem(66);
$newFontSize70: fontPxToRem(70);
$newFontSize74: fontPxToRem(74);
$newFontSize78: fontPxToRem(78);
$newFontSize80: fontPxToRem(80);
$newFontSize84: fontPxToRem(84);
$newFontSize96: fontPxToRem(96);
$newFontSize98: fontPxToRem(98);
$newFontSize122: fontPxToRem(122);


$btnBorderRadius: $size21;
$elBorderRadius: $size8;
$menuBorderRadius: $size28;
$iconSize: $size21;
$iconSizeMedium: $size30;
$iconSizeBig: $size35;
$transform-center: translate3d(-50%, -50%, 0);
/*legend{margin-bottom:$margin50;font-size:$fontSize36;color:$darkBlueTrans;font-weight:500;text-transform:uppercase;}*/

/* Colors */
$white: #ffffff;
$whiteTrans: rgba(255, 255, 255, 0.5);
$darkGray: #9F9F9F;
$warmGray: #8d8d8d;
$trueGray: #CCCCCC;
$lightGray: #f2f2f2;
$borderGray: #e4e4e4;
$lightGrayTrans: rgba(242, 242, 242, 0.5);
$darkBlue: #00252B;
$darkBlueTrans: rgba(0, 37, 43, 0.5);
$groundBlue: #00434e;
$trueBlue: #00869c;
$trueBlueTrans: rgba(0, 133, 154, 0.9);
$trueBlueTrans2: rgba(0, 133, 154, 0.2);
$lightBlue: #00C6E5;
$lightBlueTrans: rgba(0, 198, 229, 0.5);
$niceBlue: #009DB6;
$niceBlueTrans: rgba(0, 157, 182, 0.2);
$cardBlueBottom: #00A6C0;
$cardBlueBottomLight: #0095AD;
$trueOrange: #F19100;
$trueRed: #FF0000;
$trueRedTrans: rgba(255, 0, 0, 0.75);
$trueGreen: #ACE700;
$green: #8ee700;
$everGreen: #04242a;

// new colors!?!?!?!
$pineGreen: #06242a;
$tealBlueTwo: #00859a;
$darkSlateBlue: #215761;
$warmGrey: #8d8d8d;
$pinkishGrey: #cdcdcd;
$veryLightGrey: #f6f6f6;
$veryLightGrey2: #e6e6e6;
$veryLightGrey3: #e5e5e5;
$turquoiseBlue: #009fb8;
$deepTurquoise: #026b7c;
$darkGreenBlue: #1b606d;

//we should use this new colors for categories
$cultureColor: #be3b7a;
$growthColor: #75a021;
$relationshipColor: #6e3177;
$factsColor: #14a094;
$recommendationColor: #14a094;
$statementColor: $niceBlue;

$cultureColorHover: #6b1d43;
$factsColorHover: #0d554f;
$growthColorHover: #2f4209;
$relationshipColorHover: #29132c;
$statementColor: $niceBlue;


/* Fiideback List Category Colors */
$oq1Color: #7030a0;
$oq2Color: #ff00ee;
$oq3Color: #2e75b5;
$oq4Color: #ffdf00;
$oq5Color: #77461b;
$solutingsAllColor: #5B5755;

/* Group Colors - Variable */
$colDefault1: $darkBlue;
$colDefault2: $groundBlue;
$colDefault3: $tealBlueTwo;
$colGrowth1: #0E1E2F;
$colGrowth2: #0F3A5C;
$colGrowth3: #348EBB;
$colCulture1: #262616;
$colCulture2: #5F5D21;
$colCulture3: #DFE054;
$colRelationship1: #350D1E;
$colRelationship2: #882735;
$colRelationship3: #D96E78;
$colFacts1: #26211D;
$colFacts2: #4D4432;
$colFacts3: #9A8462;
$colSolutings1: #F5F5F5;
$colSolutings2: #F9F9F9;

$roboto: 'Roboto', sans-serif;