.user-list-page{
  ul.list-with-buttons {
    li.deactivated{
      .text{
        h3{
          color: $pinkishGrey;
        }
      }
    }
  }

  .user-filter {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax($size200, auto));
    grid-gap: $size26;
  }

}
