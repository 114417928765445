.open-discussions-content {
  padding-top: $size70;
  @include upToPhoneLarge {
    padding-top: $size20;
  }

  .card {
    cursor: pointer;

    .card-header-icon {
      &.growth {
        background-color: $growthColor;
      }

      &.culture {
        background-color: $cultureColor;
      }

      &.relationship {
        background-color: $relationshipColor;
      }

      &.facts {
        background-color: $factsColor;
      }

      &.recommendation {
        background-color: $recommendationColor;
      }

      &.statement {
        background-color: $statementColor;
      }
    }

    .card-body > .card-header {
      .card-header-title {
        h2 {
          text-transform: none;
          text-transform: initial;
        }
      }

      .open-discussion-item-status {
        display: grid;
        grid-auto-rows: min-content;
        grid-auto-columns: min-content;
        grid-auto-flow: column;

        grid-gap: $size20;
        align-self: center;
        justify-self: center;
        align-items: center;
        justify-items: center;
        padding-right: $size30;
        position: relative;

        span.unreadStatus {
          background-color: $trueOrange;
          border-radius: 50%;
          font-size: $newFontSize20;
          font-weight: bold;
          line-height: emCalc(20, 50);
          text-align: center;
          width: $size12;
          height: $size12;
          position: absolute;
          top: -$size10;
          right: $size20;
        }

        i {
          font-size: $newFontSize30;
          color: $trueBlue;
        }
      }
    }
  }
}

